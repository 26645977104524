
<main-frame-layout #mainFrame (creditChanged)="loadPaymentState()">
	<div class="content-area">

		<div class="clr-row" *ngIf="showMuwOnlyAcknowledgement">
			<div class="card">
				<div class="card-header" i18n>
					Conditions of use
				</div>
				<div class="card-block acknowledgement">
					<h4>Intended use, terms and conditions</h4>
					<p>The AutoML Service (Service) developed by Dedicaid GmbH  (Dedicaid - a wholly owned subsidary of Telix Pharmaceuticals Limited) is hosted at the ITSC of MUV and is <b>only allowed to be utilized for research and educational purposes</b> by Medical University of Vienna (MUV) employees and students. No trained models shall be used in any clinical decision either within MUV or outside. The Service and all support by Dedicaid or MUV is provided ‘as is’ with no express or implicit warranty. Dedicaid and MUV shall not be liable for compensation with regard to any damages sustained by the user using the Services.</p>
					<p>The Service is only acessible within the MUV domain, and any model building activities are logged within the Service per MUV credentials. No intellectual property of Dedicaid, including the trained models are allowed to be utilized for any personal or commercial activitiy. In case you are interested in commercialization endevours based on the findings of your MUV data analysis, please contact the Technology Transfer Office (TTO) of MUV. By using the Service, you automatically agree with these terms and conditions.</p> 

					<h4>Mandatory acknowledgement</h4>
					<p>In case you publish or present any of your findings provided by the Service, you are <b>obliged to give the following acknowledgement</b> in your paper/presentation or any public material:</p>
					<blockquote>"The machine learning models and their cross-validaition were performed by the Automated Machine Learning (AutoML) service of Dedicaid GmbH (a wholly owned subsidary of Telix Pharmaceuticals Limited) hosted at the Medical University of Vienna (MUV)."</blockquote>

					<p>Use this paragraph to support reporting the findings of the Service:</p>
					<blockquote>"The evaluation of the data in this study was performed by automated machine learning (AutoML) principles. This included automated multi-fold Monte Carlo cross-validation with 80-20% train-test split ratios, where the test data was balanced in order to avoid under or overrepresenting any of the classification subgroups. In each fold, automated data preprocessing in combination with mixed-stacked ensemble learning was performed over the training subset, followed by evaluating the test subset. Predictive performance estimation was done by confusion matrix analytics."</blockquote>
				</div>
			</div>
		</div>

		<div class="clr-row">
			<div class="clr-col-lg-5 clr-col-md-8 clr-col-12">
				<div class="card" *ngIf="paymentState">
					<div class="card-header" i18n>
						Payment &amp; Credit Status
					</div>
					<div class="card-block">
						<div class="card-text">
							<div class="single-row">
								<div class="subtitle" i18n>Credit balance:</div>
								<span class="credit">{{ paymentState.credit | currencyAmount }} {{ paymentState.currency }}</span>
							</div>
							<div class="single-row">
								<div class="subtitle" i18n>Saved card:</div>
								<span class="saved-card" *ngIf="paymentState.card; else noCard">{{ paymentState.card.brand }} xxxx-{{ paymentState.card.last4 }}</span>
								<ng-template #noCard><span class="no-saved-card" i18n>none</span></ng-template>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<button class="btn btn-sm btn-link" (click)="mainFrame.openAddCredit()" i18n>Add credit</button>
						<button class="btn btn-sm btn-link" *ngIf="!paymentState.card" (click)="mainFrame.openSavedCard()" i18n>Save card</button>
						<button class="btn btn-sm btn-link" (click)="showTransactions()" i18n>My Transactions</button>
					</div>
				</div>

				<div class="card" *ngIf="automlStats">
					<div class="card-header" i18n>
						AutoML Job Queue Statistics
					</div>
					<div class="card-text">
						<table class="stats">
							<tr>
								<th></th>
								<th colspan="2" i18n>Your jobs</th>
								<th i18n>All jobs</th>
							</tr>
							<tr>
								<th i18n>Pending</th>
								<td>{{ automlStats.user.pending }}</td>
								<td rowspan="2" [class.nopadding]="automlStats.activeUserJobLimit === null">
									<div *ngIf="automlStats.activeUserJobLimit !== null">
										<span class="limit">/ {{ automlStats.activeUserJobLimit }}</span>
										<span class="limit-text" i18n>(limit)</span>
									</div>
								</td>
								<td>{{ automlStats.global.pending }}</td>
							</tr>
							<tr>
								<th i18n>Running</th>
								<td>{{ automlStats.user.running }}</td>
								<td>{{ automlStats.global.running }}</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>

	</div>
</main-frame-layout>