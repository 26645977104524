import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AfterOauthLoginComponent } from './after-oauth-login/after-oauth-login.component';
import { LoginComponent } from './login/login.component';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { RegistrationComponent } from './registration/registration.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AccountActivateComponent } from './account-activate/account-activate.component';
import { AccountRejectComponent } from './account-reject/account-reject.component';
import { DeviceLoginDoneComponent } from './device-login-done/device-login-done.component';
import { ForgottenPasswordComponent } from './login/forgotten-password/forgotten-password.component';
import { NeedAuthGuard } from './needauthguard';
import { NeedRootGuard } from './needrootguard';
import { AccountUnapprovedComponent } from './account-unapproved/account-unapproved.component';
import { CryptoUnavailableComponent } from './login/crypto-unavailable/crypto-unavailable.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { DeletionComponent } from './deletion/deletion.component';
import { DeletionAfterOauthComponent } from './deletion-after-oauth/deletion-after-oauth.component';
import { UnsupportedBrowserComponent } from './login/unsupported-browser/unsupported-browser.component';
import '@cds/core/icon/register.js';
import { ClarityIcons, exclamationTriangleIcon } from '@cds/core/icon';
import { MuwDisclaimerPopupComponent } from './login/muw-disclaimer-popup/muw-disclaimer-popup.component';

ClarityIcons.addIcons(exclamationTriangleIcon);

@NgModule({
  declarations: [
    LoginComponent,
    AfterOauthLoginComponent,
    RegistrationComponent,
    AccountActivateComponent,
    AccountRejectComponent,
    DeviceLoginDoneComponent,
    ForgottenPasswordComponent,
    AccountUnapprovedComponent,
    CryptoUnavailableComponent,
    PasswordRecoveryComponent,
    DeletionComponent,
    DeletionAfterOauthComponent,
    UnsupportedBrowserComponent,
    MuwDisclaimerPopupComponent,
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaV3Module,
  ],
  providers: [
    CookieService,
    NeedAuthGuard,
    NeedRootGuard,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lf0jv4UAAAAAA6AiFEiVhU515iEspcswtujs9cM' },
    { provide: 'Window', useValue: window },
  ]
})
export class AuthenticationModule {}
