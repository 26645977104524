<clr-modal [(clrModalOpen)]="opened">
    <h3 class="modal-title" i18n>Add Credit</h3>
    <div class="modal-body">
        <div *ngIf="busy">
			<span class="spinner spinner-inline">
				Loading...
			</span>
			<span i18n>
				Loading...
			</span>
		</div>

		<div *ngIf="!busy">
			<clr-tabs>
				<clr-tab>
					<button clrTabLink id="link1" i18n>Top-up</button>
					<clr-tab-content id="content1" *clrIfActive>
						<div class="amount">
							<label i18n>Amount:</label> <input type="number" width="4" min="1" class="clr-input" [formControl]="amount" /> <span *ngIf="currency">{{ currency.toUpperCase() }}</span>
						</div>
						<div id="card-element" #cardElement class="field"></div>
						<div class="charge-button-line">
							<button class="btn" (click)="chargeEnteredCard()" [disabled]="processing" i18n>Top up</button>
						</div>
					</clr-tab-content>
				</clr-tab>

				<clr-tab>
					<button clrTabLink id="link2" [disabled]="!haveSavedCard" i18n>Charge saved card</button>
					<clr-tab-content id="content2" *clrIfActive>
						<div class="amount">
							<label i18n>Amount:</label> <input type="number" class="amount" width="4" min="1" class="clr-input" [formControl]="amountFromSaved" /> <span *ngIf="currency">{{ currency.toUpperCase() }}</span>
						</div>
						<div class="charge-button-line">
							<button class="btn" (click)="chargeSavedCard()" [disabled]="processing" i18n>Charge</button>
						</div>
					</clr-tab-content>
				</clr-tab>
			</clr-tabs>
		</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="opened = false" i18n>Close</button>
    </div>
</clr-modal>
