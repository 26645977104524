import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate, Router } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FirstLoginComponent } from './first-login/first-login.component';
import { DashboardTransactionsComponent } from './dashboard/dashboard-transactions/dashboard-transactions.component';
import { MyInvoicesComponent } from './my-invoices/my-invoices.component';
import { NeedAuthGuard } from './authentication/needauthguard';
import { NeedRootGuard } from './authentication/needrootguard';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, canActivate: [NeedAuthGuard] },
  { path: 'dashboard/transactions', component: DashboardTransactionsComponent, canActivate: [NeedAuthGuard] },
  { path: 'invoices', component: MyInvoicesComponent, canActivate: [NeedAuthGuard] },
  { path: 'first-login', component: FirstLoginComponent, canActivate: [NeedAuthGuard] },
  { path: 'automl', loadChildren: () => import('./auto-ml/auto-ml.module').then(m => m.AutoMLModule), canActivate: [NeedAuthGuard] },

  { path: 'login', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'user-management', loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule), canActivate: [NeedRootGuard] },
  { path: 'vending', loadChildren: () => import('./vending/vending.module').then(m => m.VendingModule), canActivate: [NeedRootGuard] },
  { path: 'radiomics', loadChildren: () => import('./radiomics/radiomics.module').then(m => m.RadiomicsModule), canActivate: [NeedAuthGuard] },
  { path: 'cdss', loadChildren: () => import('./dss/dss.module').then(m => m.DssModule), canActivate: [NeedAuthGuard] },
  { path: 'sharing', loadChildren: () => import('./sharing/sharing.module').then(m => m.SharingModule) },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
